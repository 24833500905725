export default {
  thisBaseOn: 'Основан на',
  baseOnL18n: 'Интернационализация этого проекта основана на',
  baseOn: 'основан на',
  documentation: 'Документация',
  palette: 'Свою палитру можно сгененрировать на',
  theme: 'Выберите цветовую схему:',
  dropHere: 'Для загрузки перетащите файл сюда',
  tinymceTips: 'Онлайновый текстовый редактор, выпущенный в виде программного обеспечения '
    + 'с открытым исходным кодом под LGPL.',
  imageUploadTips: 'Если собираетесь использовать, то лучше проверить официальную версию.',
  dropzoneTips: '',
  stickyTips: 'при прокрутке странице будет закреплен вверху',
  backToTop: 'Когда страница прокручивается до указанной позиции, '
    + 'в правом нижнем углу появляется кнопка «Вверх»',
  draggable: 'Позволяет перетаскивать и панели и элементы внутри',
};
