export default {
  thisBaseOn: '这是基于',
  baseOnL18n: '该项目的国际化基于',
  baseOn: '是根据',
  documentation: '文献资料',
  palette: '您可以在上生成调色板',
  theme: '选择配色方案：',
  dropHere: '将文件拖放到此处进行上传',
  tinymceTips: '在线富文本编辑器作为LGPL下的开源软件发布。',
  imageUploadTips: '改性。 如果要使用它，最好使用正式版本。',
  dropzoneTips: '',
  stickyTips: '当页面滚动到预设位置时，将在顶部粘贴。',
  backToTop: '当页面滚动到指定位置时，“返回顶部”按钮将显示在右下角',
  draggable: '允许您在其中拖动面板和项目',
};
